import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Layout from 'components/layout/Layout'

const Image = styled(Plaatjie)`
  height: 500px;
  max-width: 2000px;
  @media (max-width: 575px) {
    height: 350px;
  }
`

const Block = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (min-width: 768px) {
    margin-top: -7.5rem;
  }
  @media (max-width: 767px) {
    margin-top: -5.5rem;
  }
  @media (max-width: 575px) {
    margin-top: -2.5rem;
  }

  & h1 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    border-bottom: 1px solid ${({ theme }) => theme.color.grey};
    @media (min-width: 992px) {
      font-size: 28px;
    }
  }
`

interface NotFoundPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.notFoundPageQueryQuery>(graphql`
    query notFoundPageQuery {
      fields: wpComponent(databaseId: { eq: 773 }) {
        notfoundpage {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 2000)
              }
            }
          }
          bannerdescription
          description
        }
      }
    }
  `)

  return (
    <Layout>
      <section className="mb-5">
        <div className="d-flex justify-content-center">
          <Image alt="banner" image={fields?.notfoundpage?.image} />
        </div>
        <div className="container h-100">
          <div className="row">
            <div className="col-lg-7 col-xl-5">
              <Block className="px-md-5 py-md-4 px-3 py-2">
                {fields?.notfoundpage?.bannerdescription && (
                  <ParseContent
                    content={fields.notfoundpage.bannerdescription}
                    className="ps-3 pb-4"
                  />
                )}
              </Block>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex">
                <ParseContent content={fields?.notfoundpage?.description} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
